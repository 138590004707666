import { useState, useEffect, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAccessToken } from '../../hooks';
import {
	transactionsData,
	transactionsGet,
	transactionsError,
  transactionsDateRange,
} from '../../redux/transactionsSlice';
import { soLinesReset, soLinesNo } from '../../redux/salesOrderSlice';
import { DateCell, FloatCell } from '../reusable/Table';
import { DateRangeView } from '../reusable/DataViews';
import Api from '../../Api';


function Movements({ setView }) {
  const getAccessToken = useAccessToken();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.transactions);

  const [mode, setMode] = useState('view-data');

  // Effect for getting data
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const params = [
        { key: 'start', value: data.start },
        { key: 'end', value: data.end },
      ];
      const res = await Api.GET('/transactions', { accessToken: token, params: params });
      if (res.status === 200) {
				dispatch(transactionsData(res?.data));
      } else if (res.status === 470) {
        return;
      } else {
				dispatch(transactionsError(res.status));
			}
    }

    if (data.get) {
      dispatch(transactionsData(null));
			dispatch(transactionsGet(false));
      getData();
    }
  }, [getAccessToken, data, dispatch]);

  const viewLines = useCallback((line) => {
    const orderNo = line.OrderNo;
    dispatch(soLinesReset());
    dispatch(soLinesNo(orderNo));
    setView('so-lines');
  }, [dispatch, setView]);

  const columns = useMemo(() => [
    {
      Header: 'Movement Type',
      accessor: 'type',
      filter: true,
      filterType: 'movement-type',
    },
    {
      Header: 'Date',
      accessor: 'postingDate',
      filter: false,
      filterType: 'search',
      Cell: DateCell,
    },
    {
      Header: 'Product Code',
      accessor: 'itemNo',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Description',
      accessor: 'description',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Vintage',
      accessor: 'vintage',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Bottle Vol (cl)',
      accessor: 'unitVol',
      filter: true,
      filterType: 'between',
    },
    {
      Header: 'Transaction UoM',
      accessor: 'unitOfMeasure',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Quantity',
      accessor: 'qty',
      filter: true,
      filterType: 'between',
    },
    {
      Header: '9L Cases',
      accessor: 'qtyEqCases',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Charge',
      accessor: 'lineCost',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Duty Type',
      accessor: 'duty',
      filter: true,
      filterType: 'duty-type',
    },
    {
      Header: 'Order No',
      accessor: 'OrderNo',
      filter: true,
      filterType: 'search',
      onClick: viewLines,
    },
    {
      Header: 'Name',
      accessor: 'Name',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Address',
      accessor: 'Address',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Post Code',
      accessor: 'PostCode',
      filter: true,
      filterType: 'search',
    },
  ], [viewLines]);

  const setError = useCallback((_error) => {
    dispatch(transactionsError(_error));
  }, [dispatch]);
  const setGet = useCallback((_status) => {
    dispatch(transactionsGet(_status));
  }, [dispatch]);
  const setDateRange = useCallback((_range) => {
    dispatch(transactionsDateRange(_range));
  }, [dispatch]);

  return (
    <div className="h-full w-full">
      <DateRangeView
        page="movements"
        data={data}
        setGet={setGet} setError={setError}
        setDateRange={setDateRange}
        columns={columns}
        mode={mode} setMode={setMode}
        title="Stock Movements"
        fileName="stock-movements.csv"
      />
    </div>
  );
}

export default memo(Movements);
