import { useState, useMemo, memo, useEffect, useRef } from 'react';
import { format } from 'date-fns';

import { useMyTable } from '../../hooks.js';
import { FilterBox } from '../reusable/TableFilters.js';

export function BoolCell({value}) {
  return value > 0.001 ? 'Yes' : 'No';
}

export function FloatCell({value}) {
  const rounded = value.toFixed(2);
  return rounded;
}

export function DateCell({value}) {
  return (format(new Date(value), 'dd/MM/yyyy'));
}

function ToggleFilterBoxButton({ showFilters, setShowFilters }) {
  return (
    <button
      className="w-full h-full text-txtTblHdr text-sm hover:underline bg-bgTblHdr hover:bg-bgTblHdrHov rounded-t-2xl flex flex-col justify-around"
      onClick={() => setShowFilters(!showFilters)}
    >
      <div className="w-full text-center">Table Filters</div>
    </button>
  );
}






function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => {
      setIntersecting(entry.isIntersecting);
    }
  ), []);

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return [isIntersecting, setIntersecting];
}








const TABLE = memo(({ tableInstance, showColumnTotals }) => {

  const ref = useRef(null);
  const [isVisible, setIsVisible] = useOnScreen(ref);
  const [rowsDisplayed, setRowsDisplayed] = useState(50);

  useEffect(() => {
    if (isVisible) setRowsDisplayed(r => r + 50);
  }, [isVisible]);

  const {
    data,
    columns,
    columnTotals,
    sortAsc,
    sortDesc,
  } = tableInstance;

  useEffect(() => {
    setIsVisible(false);
    setRowsDisplayed(50);
  }, [tableInstance, setIsVisible]);

  return (
    <div className="h-full w-full overflow-auto">
      <table className="min-w-[100%] w-max text-left text-sm">
        <thead className="sticky top-0 bg-bgTblHdr text-txtTblHdr">
          <tr>
            {columns.map((column, idx) => {
              return (
                <th
                  key={idx}
                  className={column.sort === '' ? "h-full hover:bg-bgTblHdrHov"
                    : "h-full bg-bgTblHdrHov"
                  }
                >
                  <button
                    className={column?.filterType === 'between'
                      ? "h-full w-full py-[.5em] px-[.5em] text-right"
                      : "h-full w-full py-[.5em] px-[.5em] text-left"
                    }
                    onClick={column.sort === 'asc'
                      ? () => sortDesc(column.accessor)
                      : () => sortAsc(column.accessor)
                    }
                  >
                    {column.Header}
                  </button>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((line, lineIdx) => {
            if (lineIdx >= rowsDisplayed) return null;
            return (
              <tr
                className={lineIdx % 2 === 0 ? "bg-bgTblRow2" : "bg-bgTblRow1"}
                key={lineIdx}
              >
                {columns.map((column, colIdx) => {
                  return (
                    <td
                      className={column.onClick ? "text-txtTblData border-y-2 border-bdrTblRows cursor-pointer"
                        : "text-txtTblData border-y-2 border-bdrTblRows"}
                      style={{
                        textAlign: column.filterType === 'between' ? 'right' : 'left',
                      }}

                      key={colIdx}
                      onClick={column.onClick ? () => column.onClick(line) : null}
                    >
                      <div className="px-[.5em]">
                        {column.Cell ?
                          (<column.Cell value={line[column.accessor]}/>) :
                          (line[column.accessor])
                        }
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr ref={ref} className='h-[1px]'></tr>
        </tbody>
        {showColumnTotals ?
          <tfoot className="sticky bottom-0 bg-bgTblHdr text-txtTblHdr">
            <tr>
              {columns.map((column, idx) => {
                const total = columnTotals[column?.accessor];
                return (
                  <td key={idx} className='py-[.5em] px-[.5em] text-right font-bold'>
                    {total ? total?.toFixed(2) : null}
                  </td>
                );
              })}
            </tr>
          </tfoot>
        : <></>}
      </table>
    </div>
  );
});



export function Table({ data, columns, showColumnTotals }) {

  const rawColumns = useMemo(() => columns, [columns]); // will the dependency arrays cause issues?
  const rawData = useMemo(() => data, [data]);

  const myTable = useMyTable(rawData, rawColumns);

  const [showFilters, setShowFilters] = useState(true);

  return (
    <div className="h-full w-full">
      <div className="h-[5%] w-full">
        <ToggleFilterBoxButton showFilters={showFilters} setShowFilters={setShowFilters} />
      </div>

      <div className={showFilters ? "h-[30%] w-full overflow-y-auto bg-bgTblRow2" : "h-[0%] w-fit overflow-y-clip"}>
        <FilterBox tableInstance={myTable} showFilters={showFilters} />
      </div>

      <div className={showFilters ? "h-[65%] w-full overflow-y-auto" : "h-[95%] w-full overflow-y-auto"}>
        <TABLE tableInstance={myTable} showColumnTotals={showColumnTotals} />
      </div>
    </div>
  );
}




export function Table2({ data, columns }) {
  const rawColumns = useMemo(() => columns, [columns]); // will the dependency arrays cause issues?
  const rawData = useMemo(() => data, [data]);

  const myTable = useMyTable(rawData, rawColumns);

  return (
    <div className="h-full w-full overflow-y-auto">
      <TABLE tableInstance={myTable} />
    </div>
  );
}




