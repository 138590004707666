import { useMemo, useState, useEffect, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAccessToken } from '../../hooks';
import Api from '../../Api';
import {
  soHdrsData,
  soHdrsError,
  soHdrsGet,
  soHdrsDateRange,
} from '../../redux/salesOrderHeadersSlice';
import { soLinesReset, soLinesNo } from '../../redux/salesOrderSlice';
import { DateRangeView } from '../reusable/DataViews';
import { DateCell, BoolCell } from '../reusable/Table';



export default memo(function SalesOrderHeaders({ setView }) {
  const getAccessToken = useAccessToken();

  const dispatch = useDispatch();
  const hdrs = useSelector((state) => state.soHdrs);

  const [mode, setMode] = useState('view-data');

  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const params = [
        { key: 'start', value: hdrs.start },
        { key: 'end', value: hdrs.end },
      ];
      const res = await Api.GET('/sales-orders/headers', { accessToken: token, params: params });
      if (res.status === 200) {
				dispatch(soHdrsData(res?.data));
      } else if (res.status === 470) {
        return;
      } else {
				dispatch(soHdrsError(res.status));
			}
    }

    if (hdrs.get) {
			dispatch(soHdrsGet(false));
			dispatch(soHdrsData(null));
      getData();
    }
  }, [dispatch, getAccessToken, hdrs]);


  const setError = useCallback((_error) => {
    dispatch(soHdrsError(_error));
  }, [dispatch]);
  const setGet = useCallback((_status) => {
    dispatch(soHdrsGet(_status));
  }, [dispatch]);
  const setDateRange = useCallback((_range) => {
    dispatch(soHdrsDateRange(_range));
  }, [dispatch]);

  const viewLines = useCallback((line) => {
    const orderNo = line.orderNo;
    dispatch(soLinesReset());
    dispatch(soLinesNo(orderNo));
    setView('so-lines');
  }, [dispatch, setView]);
  

  const columns = useMemo(() => [
    {
      Header: 'Order No',
      accessor: 'orderNo',
      filter: true,
      filterType: 'search',
      onClick: viewLines,
    },
    {
      Header: 'Order Date',
      accessor: 'orderDate',
      filter: false,
      filterType: 'search',
      Cell: DateCell,
    },
    {
      Header: 'Raised Date',
      accessor: 'raisedDate',
      filter: false,
      filterType: 'search',
      Cell: DateCell,
    },
    {
      Header: 'Shipped',
      accessor: 'shipped',
      filter: true,
      filterType: 'bool',
      Cell: BoolCell,
    },
    {
      Header: 'Name',
      accessor: 'custName',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Address',
      accessor: 'custAddr',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Post Code',
      accessor: 'custPostCode',
      filter: true,
      filterType: 'search',
    },
  ], [viewLines]);


  return (
    <div className="h-full w-full">
      <DateRangeView
        page='so-hdrs'
        data={hdrs}
        setGet={setGet} setError={setError}
        setDateRange={setDateRange}
        columns={columns}
        mode={mode} setMode={setMode}
        title="Sales Orders"
        fileName="sales-headers.csv"
      />
    </div>
  )
});
