

export function escapeRegex(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '');
}


export function bubbleSortAsc(data, accessor) {
  let newData = [...data];
  for (let i = 0; i < newData.length; i++) {
    for (let j = 0; j < i; j++) {
      const ith = newData[i];
      const jth = newData[j];
      if (ith[accessor] < jth[accessor]) {
        newData[i] = jth;
        newData[j] = ith;
      }
    }
  }
  return newData;
}

export function bubbleSortDesc(data, accessor) {
  let newData = [...data];
  for (let i = 0; i < newData.length; i++) {
    for (let j = 0; j < i; j++) {
      const ith = newData[i];
      const jth = newData[j];
      if (ith[accessor] > jth[accessor]) {
        newData[i] = jth;
        newData[j] = ith;
      }
    }
  }
  return newData;
}

