import { useEffect, useState, useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  bubbleSortAsc,
  bubbleSortDesc,
  escapeRegex,
} from './helpers';

export function useLogout() {
  const {
    logout,
  } = useAuth0();
  const logoutFunc = useCallback(() => {
    logout();
  }, [logout]);
  return logoutFunc;
}



export function useAccessToken() {
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  async function getAccessToken() {

    const authParams = {
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      //scope: 'read:root',
    };

    let token = null;
    try {
      token = await getAccessTokenSilently({
        authorizationParams: authParams,
      });
    } catch (err) {
      token = await getAccessTokenWithPopup({
        authorizationParams: authParams,
      });
    }

    console.log(token);
    return token;
  }

  return getAccessToken;
}







export function useMyTable(data, columns) {

  const [newColumns, setNewColumns] = useState(columns.map((col) => {
    return {...col, filterValue: '', sort: ''};
  }));


  /* =========================================================
    SHOW/HIDE COLUMNS
  */
  const [displayedColumns, setDisplayedColumns] = useState({});
  useEffect(() => {
    let initShowColumns = {};
    for (let i = 0; i < columns.length; i++) {
      initShowColumns[columns[i].accessor] = true;
    }
    setDisplayedColumns(initShowColumns);
  }, [setDisplayedColumns, columns]);

  /*
  const displayColumns = useCallback((accessorValuePairs) => {
    const newDisplayedColumns = {};
    for (let i = 0; i < columns.length; i++) {
      newDisplayedColumns[columns[i].accessor] = displayedColumns[columns[i].accessor];
    }
    for (let i = 0; i < accessorValuePairs; i++) {
      newDisplayedColumns[accessorValuePairs[i].accessor] = accessorValuePairs.display;
    }
    setDisplayedColumns(newDisplayedColumns);
  }, [columns, displayedColumns, setDisplayedColumns]);
  */


  /* =========================================================
    SORTING FUNCTIONS
  */

  const [unfilteredData, setUnfilteredData] = useState(data);


  const sortAsc = useCallback((accessor) => {
    const newUnfilteredData = bubbleSortAsc(unfilteredData, accessor);
    setNewColumns(newColumns.map((col) => {
      if (col.accessor === accessor) {
        return {...col, sort: 'asc'};
      }
      return {...col, sort: ''};
    }));
    setUnfilteredData(newUnfilteredData);
  }, [newColumns, unfilteredData]);


  const sortDesc = useCallback((accessor) => {
    const newUnfilteredData = bubbleSortDesc(unfilteredData, accessor);
    setNewColumns(newColumns.map((col) => {
      if (col.accessor === accessor) {
        return {...col, sort: 'desc'};
      }
      return {...col, sort: ''};
    }));
    setUnfilteredData(newUnfilteredData);
  }, [newColumns, unfilteredData]);






  /* =========================================================
    FILTERING FUNCTIONS
  */

  const [appliedFilters, setAppliedFilters] = useState([]);
  const [appliedFiltersMap, setAppliedFiltersMap] = useState({});

  useEffect(() => {
    let map = {};
    for (let i = 0; i < appliedFilters.length; i++) {
      const filter = appliedFilters[i];
      map[filter.accessor] = { value: filter.value, polarity: filter.polarity };
    }
    setAppliedFiltersMap(map);
  }, [appliedFilters]);

  // Map the column accessor to its filterType.
  const columnFilterTypes = useMemo(() => {
    const result = {};
    for (let i = 0; i < columns.length; i++) {
      const accessor = columns[i].accessor;
      const filterType = columns[i].filterType;
      result[accessor] = filterType;
    }
    return result;
  }, [columns]);


  const clearFilter = useCallback((accessor) => {
    const newAppliedFilters = appliedFilters.filter((filter) => filter.accessor !== accessor);
    setAppliedFilters(newAppliedFilters);
    setNewColumns(newColumns.map((col) => {
      if (col.accessor === accessor) {
        return {...col, filterValue: ''};
      }
      return col;
    }));
  }, [newColumns, appliedFilters]);

  
  const clearAllFilters = useCallback(() => {
    setAppliedFilters([]);
    setNewColumns(newColumns.map((col) => {
      return {...col, filterValue: ''};
    }));
  }, [newColumns]);


  // Add a filter to the list of filters, or update if there is
  // already a filter for that column.
  const addFilter = useCallback((accessor, value, polarity=true, escapeRegexChars=false) => {
    if (value === '') {
      // This prevents '' search filter values staying in the appliedFilters array.
      clearFilter(accessor);
      return;
    }
    if (escapeRegexChars) {
      value = escapeRegex(value);
    }
    let newAppliedFilters = [...appliedFilters];
    let columnFiltered = false;
    for (let i = 0; i < newAppliedFilters.length; i++) {
      if (newAppliedFilters[i].accessor === accessor) {
        newAppliedFilters[i].value = value;
        newAppliedFilters[i].polarity = polarity;
        columnFiltered = true;
      }
    }
    if (!columnFiltered) {
      newAppliedFilters.push({accessor: accessor, value: value, polarity: polarity});
    }
    setAppliedFilters(newAppliedFilters);
    setNewColumns(newColumns.map((col) => {
      if (col.accessor === accessor) {
        return {...col, filterValue: value};
      }
      return col;
    }));
  }, [newColumns, appliedFilters, clearFilter]);




  /* =========================================================
    SETTING THE VISIBLE DATA
  */

  const [visibleData, setVisibleData] = useState(data);

  useEffect(() => {
    let newVisibleData = [];

    // Loop through the unfiltered rows.
    for (let i = 0; i < unfilteredData.length; i++) {
      const row = unfilteredData[i];
      let keepRow = true;

      // Loop through the applied filters.
      for (let j = 0; j < appliedFilters.length; j++) {
        const filtAccessor = appliedFilters[j].accessor;
        const filtValue = appliedFilters[j].value;
        const polarity = appliedFilters[j].polarity;
        const rowValue = row[filtAccessor];

        switch (columnFilterTypes[filtAccessor]) {
          case 'search':
            keepRow = rowValue.toLowerCase().search(filtValue.toLowerCase()) >= 0;
            break;

          case 'between':
            const min = Number(filtValue.min);
            const max = Number(filtValue.max);

            if (isNaN(min) && !isNaN(max)) {
              keepRow = rowValue <= max;
            } else if (!isNaN(min) && isNaN(max)) {
              keepRow = rowValue >= min;
            } else if (isNaN(min) && isNaN(max)) {
              keepRow = true;
            } else {
              keepRow = rowValue >= min && rowValue <= max;
            }
            break;

          case 'bool':
            keepRow = Number(filtValue) === rowValue;
            break;

          default:
            keepRow = filtValue === row[filtAccessor];
            break;
        }

        keepRow = polarity ? keepRow : !keepRow;

        // If not keeping the row, don't keep checking the other applied filters.
        if (!keepRow) {
          break;
        }
      }
      if (keepRow) {
        newVisibleData.push(row);
      }
    }
    setVisibleData(newVisibleData);
  }, [unfilteredData, appliedFilters, columnFilterTypes]);




  /* =========================================================
    SETTING THE COLUMN TOTALS
  */

    const [columnTotals, setColumnTotals] = useState({});

    useEffect(() => {
      let totals = {};
      let columnTotalsAccessors = [];
  
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].columnTotal) {
          totals[columns[i].accessor] = 0;
          columnTotalsAccessors.push(columns[i].accessor);
        }
      }
  
      for (let i = 0; i < visibleData.length; i++) {
        const row = visibleData[i];
        for (let j = 0; j < columnTotalsAccessors.length; j++) {
          const accessor = columnTotalsAccessors[j];
          totals[accessor] += (row[accessor] ?? 0);
        }
      }
  
      setColumnTotals(totals);
  
    }, [columns, visibleData]);






  return {
    data: visibleData,
    columns: newColumns,
    displayedColumns: displayedColumns,
    setDisplayedColumns: setDisplayedColumns,
    columnTotals: columnTotals,
    sortAsc: sortAsc,
    sortDesc: sortDesc,
    appliedFilters: appliedFilters,
    appliedFiltersMap: appliedFiltersMap,
    addFilter: addFilter,
    clearFilter: clearFilter,
    clearAllFilters: clearAllFilters,
  };
}



