import { useEffect, useCallback, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAccessToken } from '../../hooks';
import {
	stockData,
	stockGet,
	stockError
} from '../../redux/stockSlice';
import { FloatCell } from '../reusable/Table';
import { SimpleView } from '../reusable/DataViews';
import Api from '../../Api';

function LiveStock() {
  const getAccessToken = useAccessToken();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.stock);

  // Effect for getting stock data
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const res = await Api.GET('/stock', { accessToken: token });
      if (res.status === 200) {
        dispatch(stockData(res?.data));
      } else if (res.status === 470) {
        return;
      } else {
        dispatch(stockError(res.status));
			}
    }

    if (data.get) {
      dispatch(stockData(null));
      dispatch(stockGet(false));
      getData();
    }
  }, [getAccessToken, data, dispatch]);

  const columns = useMemo(() => [
    {
      Header: 'Product Code',
      accessor: 'sku',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Description',
      accessor: 'description',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Vintage',
      accessor: 'vintage',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Case UoM',
      accessor: 'caseUOM',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Bottle Vol (cl)',
      accessor: 'unitVol',
      filter: true,
      filterType: 'between',
    },
    {
      Header: 'Cases',
      accessor: 'remainingCases',
      filter: true,
      filterType: 'between',
    },
    {
      Header: 'Bottles',
      accessor: 'remainingBottles',
      filter: true,
      filterType: 'between',
    },
    {
      Header: '9L Cases',
      accessor: 'remainingEqCases',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Duty Type',
      accessor: 'duty',
      filter: true,
      filterType: 'duty-type',
    },
  ], []);

  const setError = useCallback((_error) => {
    dispatch(stockError(_error));
  }, [dispatch]);

  const setGet = useCallback((_status) => {
    dispatch(stockGet(_status));
  }, [dispatch]);

  return (
    <div className="h-full w-full">
      <SimpleView
        page="stock"
        data={data}
        title="Stock" columns={columns}
        setError={setError} setGet={setGet}
        fileName="stock.csv"
      />
    </div>
  );
}

export default memo(LiveStock);

