import { useState, useEffect } from 'react';

import { ApplyFilterButton } from './Buttons';

export function BoolFilter({ column, addFilter, clearFilter, appliedFiltersMap }) {

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>
      <td>
        <div className="h-full w-full flex justify-between">

          <select
            className='w-full border-2 border-bdrTblRows rounded-md'
            onChange={(e) => {
              if (e.target.value === '') {
                clearFilter(column.accessor);
              } else {
                addFilter(column.accessor, e.target.value, true, false);
              }
            }}
            value={appliedFiltersMap[column.accessor]?.value ?? ''}
          >
            <option value=''>All</option>
            <option value='1'>Yes</option>
            <option value='0'>No</option>
          </select>

          <div className="w-[.25em]"></div>
        </div>
      </td>
    </>
  )
}

export function MovementTypeColumnFilter({ column, addFilter, clearFilter, appliedFiltersMap }) {

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>
      <td>
        <div className="h-full w-full flex justify-between">

          <select
            className='w-full border-2 border-bdrTblRows rounded-md'
            onChange={(e) => {
              if (e.target.value === '') {
                clearFilter(column.accessor);
              } else {
                addFilter(column.accessor, e.target.value, true, false);
              }
            }}
            value={appliedFiltersMap[column.accessor]?.value ?? ''}
          >
            <option value=''>All</option>
            <option value='Delivery'>Delivery</option>
            <option value='Pick'>Pick</option>
            <option value='Put-Away'>Put-Away</option>
          </select>

          <div className="w-[.25em]"></div>
        </div>
      </td>
    </>
  )
}



export function DutyTypeColumnFilter({ column, addFilter, clearFilter, appliedFiltersMap }) {

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>
      <td>
        <div className="h-full w-full flex justify-between">

          <select
            className='w-full border-2 border-bdrTblRows rounded-md'
            onChange={(e) => {
              if (e.target.value === '') {
                clearFilter(column.accessor);
              } else {
                addFilter(column.accessor, e.target.value, true, false);
              }
            }}
            value={appliedFiltersMap[column.accessor]?.value ?? ''}
          >
            <option value=''>All</option>
            <option value='Duty Free'>Duty Free</option>
            <option value='Duty Paid'>Duty Paid</option>
          </select>

          <div className="w-[.25em]"></div>
        </div>
      </td>
    </>
  )
}


export function TextColumnFilter({ column, addFilter }) {

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>

      <td>
        <div className="h-full w-full flex justify-between">
          <input
            placeholder="Search"
            className="w-full border-2 border-bdrTblRows rounded-md"
            value={column.filterValue || ''}
            onChange={(e) => addFilter(column.accessor, e.target.value, true, true)}
          />
          <div className="w-[.25em]"></div>
        </div>
      </td>

    </>
  );
}






export function NumColumnFilter({ column, addFilter, clearFilter }) {
  const [range, setRange] = useState([]);

  function clear() {
    setRange([]);
    clearFilter(column.accessor);
  }

  // Effect for resetting the range if clearAllFilters() is called.
  useEffect(() => {
    if (column.filterValue === '') {
      setRange([]);
    }
  }, [column]);

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>

      <td>
        <div className="h-full w-full flex justify-between">
          <input
            type="number"
            placeholder="Min"
            className="w-[50%] border-2 border-bdrTblRows rounded-md"
            value={range[0] || ''}
            onChange={(e) => setRange([String(e.target.value), range[1]])}
          />
          <input
            type="number"
            placeholder="Max"
            className="w-[50%] border-2 border-bdrTblRows rounded-md"
            value={range[1] || ''}
            onChange={(e) => setRange([range[0], String(e.target.value)])}
          />
          <div className="w-[.25em]"></div>
          <ApplyFilterButton
            onClick={
              column.filterValue === '' ?
              () => addFilter(column.accessor, {min: range[0], max: range[1]}) :
              clear
            }
            isApplied={column.filterValue !== ''}
          />
        </div>
      </td>

    </>
  );
}





export function FilterBox({ tableInstance }) {

  const {
    columns,
    addFilter,
    clearFilter,
    clearAllFilters,
    appliedFilters,
    appliedFiltersMap,
  } = tableInstance;



  return (
    <div className="h-full w-full px-[1em] text-txtTblData">
      <div className="h-[1em]"></div>

      <div className="flex justify-left">

        <table className="h-fit text-sm mr-[2em]"><tbody>
          {columns.map((column, index) => {
            return (
              <tr key={index}>
                {column.filter && column.filterType === 'search' ?
                  <TextColumnFilter column={column} addFilter={addFilter} />
                  : column.filter && column.filterType === 'duty-type' ?
                  <DutyTypeColumnFilter
                    column={column}
                    addFilter={addFilter}
                    clearFilter={clearFilter}
                    appliedFiltersMap={appliedFiltersMap}
                  />
                  : column.filter && column.filterType === 'movement-type' ?
                  <MovementTypeColumnFilter
                    column={column}
                    addFilter={addFilter}
                    clearFilter={clearFilter}
                    appliedFiltersMap={appliedFiltersMap}
                  />
                  : column.filter && column.filterType === 'bool' ?
                  <BoolFilter
                    column={column}
                    addFilter={addFilter}
                    clearFilter={clearFilter}
                    appliedFiltersMap={appliedFiltersMap}
                  />
                  : <></>
                }
              </tr>
            );
          })}
        </tbody></table>

        <table className="h-fit text-sm ml-[2em]"><tbody>
          {columns.map((column, index) => {
            return (
              <tr key={index}>
                {column.filter && column.filterType === 'between' ?
                  <NumColumnFilter column={column} addFilter={addFilter} clearFilter={clearFilter} />
                  : <></>
                }
              </tr>
            );
          })}
        </tbody></table>

        <div className="mx-auto">
          <button
            className={appliedFilters.length === 0 ? "p-[1em] bg-bgRedButton hover:bg-bgRedButtonHov rounded-md text-txtNavHdr" :
              "p-[1em] bg-bgRedButtonHov rounded-md text-txtNavHdr"}
            onClick={clearAllFilters}
          >Reset</button>
        </div>

      </div>

      <div className="h-[1em]"></div>
    </div>
  );
}
